
<template>
  <div class="text-gray-100 border-t-4 border-teal-500 modal">
      <div class="px-4 py-5 bg-gray-700 border-b border-gray-800 sm:px-6">
        <h3 class="text-lg font-medium leading-6 text-slate-100">
          Delete Bookmark
        </h3>
      </div>

    <div class="px-8 py-4 modal-content bg-zinc-900">
      <form @submit.prevent="submit">
        <div class="mb-6">
          <p class="text-sm">
            Are you sure you want to delete the following camera bookmark?
          </p>

          <div class="flex items-center p-2 py-3 mt-2 text-sm border border-gray-300 rounded">
            <div class="flex items-center justify-center object-cover w-10 h-8 mr-2 bg-teal-600 rounded">
              <fa-icon
                icon="camera-movie"
                size="sm"
                class="text-white fa-fw"
              />
            </div>
            {{ bookmark.name }}
          </div>
        </div>
        <div class="flex items-center justify-end mt-6 btn-group">
          <button class="text-white btn" type="button" @click="$emit('close')">
            Cancel
          </button>
          </button>
          <button
            class="btn negative"
            type="button"
            @click.prevent="submit"
          >
            <fa-icon
              icon="trash-can"
              class="mr-2"
            /> Delete
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {

  name: 'BookmarDelete',

  props: {
    bookmark: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: new SparkForm({})
    }
  },

  computed: {
  },

  methods: {
    submit () {
      this.$bus.$emit('bookmark:delete', { bookmark: this.bookmark })
      this.$emit('close')
    }
  }
}
</script>
